import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io";
import { IoMdMail } from "react-icons/io";

const OurTeam = () => {
  return (
    <div className="flex flex-col items-left gap-6 md:gap-24 justify-center md:min-h-screen  md:px-44 font-urbanist md:w-auto w-5/6 py-32 md:py-0 mx-auto md:mb-12 mb-6">
      <h1 className="text-white text-3xl md:text-4xl z-50">Our Team</h1>
      <div className="flex md:flex-row flex-col gap-8 md:gap-12 justify-center">
        <div className="flex-1 flex flex-row justify-center items-center gap-4">
          <div className="w-1/3 rounded-full z-20 shadow-xl shadow-black/70">
            <img src={"../shbm.png"} className="w-full " alt="market" />
          </div>
          <div className="flex flex-col justify-center">
            <div className="px-6 md:px-12 bg-glassbg bg-opacity-70 backdrop-blur-md border border-white/15 py-6 rounded-xl z-20 flex flex-col justify-center items-center text-center md:text-left gap-1 shadow-xl shadow-black/50 ">
              <h1 className="text-white text-xl md:text-2xl ">Shubham Goyal</h1>
              <h1 className="text-white text-md md:text-xl ">CEO</h1>
              <div className="flex flex-row gap-4 mt-4">
                <IoLogoLinkedin
                  color="white"
                  size={24}
                  className="cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://in.linkedin.com/in/shubham-goyal-633201211"
                    )
                  }
                />
                <IoMdMail
                  color="white"
                  size={24}
                  className="cursor-pointer"
                  onClick={() => window.open("mailto:shubham@citrana.in")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-row justify-center gap-4 items-center">
          <div className="w-1/3 rounded-full z-20 shadow-xl shadow-black/70">
            <img src={"../ishn.png"} className="w-full" alt="market" />
          </div>
          <div className="flex flex-col justify-center ">
            <div className="px-6 md:px-12 bg-glassbg bg-opacity-70 backdrop-blur-md border border-white/15 py-6 rounded-xl z-20 flex flex-col justify-center items-center text-center md:text-left gap-1 shadow-xl shadow-black/50">
              <h1 className="text-white text-xl md:text-2xl ">
                Ishan Barpanda
              </h1>
              <h1 className="text-white text-md md:text-xl ">CTO</h1>
              <div className="flex flex-row gap-4 mt-4">
                <IoLogoLinkedin
                  color="white"
                  size={24}
                  className="cursor-pointer"
                  onClick={() =>
                    window.open("https://www.linkedin.com/in/ishanbarpanda/")
                  }
                />
                <IoMdMail
                  color="white"
                  size={24}
                  className="cursor-pointer"
                  onClick={() => window.open("mailto:ishan@citrana.in")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-12 justify-center ">
        <div className="flex-1 flex flex-row justify-center gap-4 items-center">
          <div className="w-1/3 rounded-full z-20 shadow-xl shadow-black/70">
            <img src={"./rgv.png"} className="w-full" alt="market" />
          </div>
          <div className="flex flex-col justify-center">
            <div className="px-6 md:px-12 bg-glassbg bg-opacity-70 backdrop-blur-md border border-white/15 py-6 rounded-xl z-20 flex flex-col justify-center items-center text-center md:text-left gap-1 shadow-xl shadow-black/50">
              <h1 className="text-white text-xl md:text-2xl ">Raghav Sarda</h1>
              <h1 className="text-white text-md md:text-xl ">Lead Engineer</h1>
              <div className="flex flex-row gap-4 mt-4">
                <IoLogoLinkedin
                  color="white"
                  size={24}
                  className="cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://in.linkedin.com/in/raghav-sarda-520a671b5"
                    )
                  }
                />
                <IoMdMail
                  color="white"
                  size={24}
                  className="cursor-pointer"
                  onClick={() => window.open("mailto:pratham@citrana.in")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-row justify-center gap-4 items-center">
          <div className="w-1/3 rounded-full z-20 shadow-xl shadow-black/70">
            <img src={"../skmr.png"} className="w-full" alt="market" />
          </div>
          <div className="flex-1 md:flex-none flex flex-col justify-center">
            <div className="px-6 md:px-12 bg-glassbg bg-opacity-70 backdrop-blur-md border border-white/15 py-6 rounded-xl z-20 flex flex-col justify-center items-center text-center md:text-left gap-1 shadow-xl shadow-black/50">
              <h1 className="text-white text-xl md:text-2xl ">Shreya Kumar</h1>
              <h1 className="text-white text-md md:text-xl ">CMO</h1>
              <div className="flex flex-row gap-4 mt-4">
                <IoLogoLinkedin
                  color="white"
                  size={24}
                  className="cursor-pointer"
                  onClick={() =>
                    window.open("http://linkedin.com/in/shreya-kumar1")
                  }
                />
                <IoMdMail
                  color="white"
                  size={24}
                  className="cursor-pointer"
                  onClick={() => window.open("mailto:shreya@citrana.in")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
