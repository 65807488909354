import React, { useState, useEffect } from "react";
import "./App.css";
import Demo from "./components/demo";
import Footer from "./components/footer";
import Hero from "./components/hero";
import Navbar from "./components/navbar";
import OurTeam from "./components/ourteam";
import Pricing from "./components/pricing";
import UseCases from "./components/usecases";
import { BackgroundGradientAnimation } from "./components/ui/background-gradient-animation";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating a delay of 2 seconds for the components to load
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <Navbar />
      <div className="App bg-mainbg bg-cover flex flex-col absolute z-10 overflow-x-hidden">
        <div id="hero">
          <Hero />
        </div>
        {/* <div id="use-cases">
          <UseCases />
        </div> */}
        <div id="demo">
          <Demo />
        </div>
        <div id="pricing">
          <Pricing />
        </div>
        <div id="ourteam">
          <OurTeam />
        </div>
        <div id="footer">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
